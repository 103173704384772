<template>
<div>
      <v-container
       id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 1 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 1: Homeowner Info
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                      <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                    v-model="section.column_1"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #1 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_2"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #2 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_3"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                md="4"
                            >
                                <v-text-field
                                    label="Revision #3 Date"
                                    type="date"
                                    color="black"
                                    v-model="section.column_4"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="selected_user.HomeNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field
                                    label="Address & Legal"
                                    color="black"
                                    v-model="selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field
                                    label="Projected Closing Date"
                                    type="date"
                                    color="black"
                                    v-model="selected_user.BuildEnd"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-checkbox v-model="section.column_5">
                                     <template v-slot:label>
                                        Homeowner received Homeowner's Manual
                                     </template>
                                </v-checkbox>
                            </v-col>

                          <v-col col="12" md="12">
                                <v-header><strong>Additional Information/Notes</strong><v-text-field v-model="section.column_6"/></v-header>
                            </v-col>
                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
                <v-spacer/>
                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section 1: Exterior
                    </div>
                    <div class="display-2 font-weight-light">
                    Mastic Home Exteriors
                    </div>
                    <div class="display-2 font-weight-light">
                    View color matching at: <a href='www.plygem.com' target='_blank'>www.plygem.com</a>
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                        <v-row>

                            <v-col cols="12">
                                <v-header>SHINGLES(TIME FRAME—3 WEEKS)</v-header>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>Tamko 30 year</v-subheader>
                            </v-col>
                            <v-col cols="12" md="10">
                                <v-text-field v-model="section.column_7"/>
                            </v-col>

                             <v-col cols="12">
                                <v-header>SIDING(TIME FRAME—3 WEEKS)</v-header>
                            </v-col>
                          <v-row>
                            <v-col cols="3" md="2">
                                <v-subheader>OVATION (Double 4):</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_8"></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>CARVEDWOOD Wood Grain (Double 4):</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_9"></v-select>
                            </v-col>
                            </v-row>
                          <v-row>
                            <v-col cols="12" md="2">
                                <v-subheader>DUTCHLAP (Double 4 1/2):</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_10"></v-select>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>VINYL</v-subheader>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_11"></v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>L.P. SMART MATERIALS FRONT ONLY:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_12"></v-select>
                            </v-col>
                            </v-row>
                            <v-col cols="6" md="2">
                              <v-header><strong> Siding Color:</strong></v-header>
                            </v-col>
                            <v-col cols="12" md="10">
                                <v-text-field v-model="section.column_13"/>
                            </v-col>
                            <v-col cols="12"  md="2">
                                <v-subheader>AREAS OF HOUSE FOR ACCENT:</v-subheader>
                            </v-col>
                            <v-col cols="12"  md="10">
                                <v-text-field v-model="section.column_14"/>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-header><strong>CORNERS OF HOUSE </strong>(COLOR IF DIFFERENT FROM SIDING):</v-header>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-text-field v-model="section.column_15"/>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-header><strong>SOFFIT & FASCIA:</strong></v-header>
                            </v-col>
                              <v-col cols="12" md="8">
                                <v-text-field v-model="section.column_16"/>
                            </v-col>
                          <v-col cols="12" md="2">
                                <v-header>(TIME FRAME - 3 WEEKS)</v-header>
                            </v-col>
                              <v-col md="12">
                            <p>**Note: There are some fascia colors that DO NOT come in the same color as the soffit. Therefore, the soffit may match the siding color rather than the fascia color!</p>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-header><strong>SHAKES</strong> (If applicable & color)</v-header>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="section.column_17"/>
                            </v-col>

                            <v-col cols="12" md="3">
                              <v-header><strong>BOARD & BATTEN:</strong> (If applicable & color)</v-header>
                            </v-col>
                          <v-col cols="12" md="9">
                                <v-text-field v-model="section.column_18"/>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-subheader>RAISED GABLE MATERIAL COLOR:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field v-model="section.column_19"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>GORBELS IN GABLES COLOR:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="9">
                                <v-text-field v-model="section.column_20"/>
                            </v-col>

                             <v-col cols="12" md="3">
                               <v-header><strong>WINDOW WRAPS</strong>(If applicable & color)</v-header>
                            </v-col>
                          <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_21"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-header>COLOR:</v-header>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="section.column_22"/>
                            </v-col>

                            <v-col cols="12">
                                <v-header><strong>WINDOWS</strong> </v-header>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>SLIDERS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_23"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-text-field v-model="section.column_24"/>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>CASEMENTS (OPT):</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_25"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="section.column_26"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>SINGLE HUNGS (OPT): (LOWERS OPEN ONLY)</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_27"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="section.column_28"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>DOUBLE HUNGS:(BOTH OPEN):</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_29"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="section.column_30"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>TRANSOM:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_31"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="7">
                                <v-text-field v-model="section.column_32"/>
                            </v-col>

                            <v-col cols="12" md="1">
                                <v-header><strong>VECTOR:</strong></v-header>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select :items="['WHITE (STD)', `ALMOND (+${this.price_section_one.column_1})`, `CLAY (+${this.price_section_one.column_2})`, 'Undecided']" v-model="section.column_42"></v-select>
                            </v-col>
                          <v-col cols="12" md="7">
                                <v-header>(TIME FRAME 1 WK)</v-header>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>GRIDS FRONT ONLY (+${{this.price_section_one.column_3}}.ea)(OPTIONAL)</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_33"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>GRIDS ALL WINDOWS (+${{this.price_section_one.column_4}}.ea)(OPTIONAL)</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field v-model="section.column_34"/>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>GRID STYLE:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="10">
                                <v-text-field v-model="section.column_35"/>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-header><strong>VECTOR PATIO DOOR VINYL STANDARD:</strong></v-header>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="section.column_36"/>
                            </v-col>
                          <v-col cols="12" md="4">
                              <v-header>(SAME COLOR AS WINDOW SELECTION)</v-header>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-header><strong>FRONT DOOR PAINT COLOR IF DIFFERENT THAN SIDING:</strong></v-header>
                            </v-col>
                          <v-col cols="12" md="7">
                              <v-text-field v-model="section.column_37"/>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>OPTIONAL WRAPS AROUND FRONT DOOR:
                                </v-subheader>
                            </v-col>
                          <v-col cols="12" md="2">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_38"></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-subheader>Color:<v-text-field/>
                                </v-subheader>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>STD. White<v-text-field v-model="section.column_39"/></v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>SIZE OF DOOR WRAPS:4”<v-select :items="['Yes','No', 'Undecided']" v-model="section.column_40"/>
                                </v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-subheader>SIDES/BOTTOMS, 6” TOPS<v-select :items="['Yes','No', 'Undecided']" v-model="section.column_41"/>
                                </v-subheader>
                            </v-col>
                            <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-textarea v-model="section.column_42"/></v-header>
                            </v-col>
                            <v-col cols="12" >
                              <v-header><strong>GARAGE DOOR STYLE *(SEE SECTION 4 PAGE #2)</strong></v-header>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-header><strong>GARAGE DOOR PAINT</strong> COLOR IF DIFFERENT FROM STD. WHITE (${{this.price_section_one.column_5}}.):</v-header>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_43"/>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-subheader>Time Frame-3 Weeks</v-subheader>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-subheader>OPTIONAL WRAPS AROUND GARAGE DOOR:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_44"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>COLOR:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="section.column_45"/>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-subheader>SIZE OF GARAGE DOOR WRAPS:  4”</v-subheader>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_46"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader> LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="section.column_47"/>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-subheader> 6” TOPS OF GARAGE DOOR</v-subheader>
                            </v-col>
                          <v-col cols="12" md="2">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_48"/>
                            </v-col>
                          <v-col cols="12" md="1">
                                <v-subheader> LOCATIONS:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="section.column_49"/>
                            </v-col>
                            <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-textarea v-model="section.column_50"/></v-header>
                            </v-col>

                        </v-row>
                       <v-row>

                            <v-col cols="12"><h3>VIEW ALL ITEMS LISTED BELOW AT: NORTH METRO SUPPLIES WEBSITE <a href='www.northmetrosupplies.com' target='_blank'>WWW.NORTHMETROSUPPLIES.COM</a></h3></v-col>

                            <v-col cols="12" md="4">
                                <v-header>EXTERIOR FRONT DOOR: STANDARD STEEL PANEL</v-header>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_51"/>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-subheader>OPTIONAL:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="section.column_52"/>
                            </v-col>

                             <v-col cols="12">
                                <v-header>DEAD BOLTS (NO ELECTRONIC STYLE, ${{this.price_section_one.column_6}} EACH FOR VENETIAN, ${{this.price_section_one.column_7}} each for Satin Nickel)</v-header>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>FRONT DOOR</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_53"/>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>HOUSE TO GARAGE DOOR:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_54"/>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-subheader>GARAGE SERVICE DOOR:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select :items="['Yes','No', 'Undecided']" v-model="section.column_55"/>
                            </v-col>
                            <v-col cols="12" md="1">
                                <v-subheader>OTHER:</v-subheader>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="section.column_56"/>
                            </v-col>
                            <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-textarea v-model="section.column_57"/></v-header>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-header>ADDITIONAL EARNEST MONEY $:</v-header>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="section.column_58"/>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-header>TERMS:</v-header>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="section.column_59"/>
                                </v-col>
                            <v-col cols="12">

                                    <p>
                                      <strong>I. NOTICE TO HOMEOWNER:</strong> The power company will be running electricity into your home from the street at the shortest distance.
                                        It will be your responsibility (as the homeowner) to request in writing the location of the out- side meter & circuit box inside the house, if it is to be different than where the power company intends to locate it.
                                    </p>
                                    <p>
                                        <strong>II. NOTICE TO HOMEOWNER:</strong> Any soil remaining on lot that your home is being built on, is the property of Boulder Contracting. After your home has received its proper grade requirements by the city guidelines, any unused soil will be transported to another location in need of soil.
                                    </p>
                                    <p>
                                        <strong>III. NOTICE TO HOMEOWNER:</strong> Boulder Contracting reserves the right to begin the construction of your home while you are making changes to your blueprint/drawing due to time restraints that we may be working under. By signing this form below, the homeowner understands that the house drawing may have items drawn that Boulder Contracting does not provide as standard.
                                    </p>
                                    <p>
                                        <strong>IV.  NOTICE TO HOMEOWNER:</strong> Off season construction may result in; steps, sidewalks, driveways and garage floors to be installed after your closing date. If you would like a price on installing temporary steps or sidewalks constructed for your home, please let Boulder Contracting know (in writing) on a new green sheet change form.
                                  </p>
                                  <p>
                                        <strong>V. NOTICE TO HOMEOWNER:</strong> All allowances that are in the purchase agreement/addendums may not be firmed up until last few days of final construction of home. Therefore, it is homeowner’s responsibility to make sure that these amounts are enough before final numbers are given to lender.
                                    </p>
                                    <p>
                                        <strong>VI. NOTICE TO HOMEOWNER:</strong> Individual representing Boulder Contracting is in no way liable for material content of form or for any information; inserted, left blank, or undecided per homeowner.
                                    </p>
                                    <p>
                                        <strong>VII. NOTICE TO HOMEOWNER:</strong> Boulder Contracting and Boulder Contracting Realtors are in no way liable for developer/city building requirement changes in any of the neighborhoods that we build homes in.
                                    </p>
                                    <p>
                                        <strong>VIII. NOTICE TO HOMEOWNER:</strong> If you have elected to the coordination of bringing in your own contractor to do work on your home, and Boulder Contracting has agreed upon making the payment to them - it will be the homeowners responsibility to prepare the contractor that they will be paid at the closing of your home. Similar to other Boulder Contracting contractors.
                                    </p>
                                    <p>
                                        <strong>IX. NOTICE TO HOMEOWNER:</strong> While visiting our outside vendors showrooms, you may elect to upgrade any of your selections from the standard features, which may incur additional charges above the retail price for; installation, handling and taxes. (See additional explanation on the “Notice for visiting outside vendors” form). Also some of our vendors offer additional products that we normally do not use. Some products are crossovers from our vendors, if you select & intend to purchase any of these products it will be your responsibility to insure installation and & changes needed by our vendors & payments of costs involved.
                                    </p>
                                    <p>
                                        <strong>X. NOTICE TO HOMEOWNER:</strong> Boulder Contracting and Boulder Contracting Realtors are in no way responsible for Homeowner’s lender providing appraisal lower than sale price. Homeowner will be responsible to make up the difference if the value comes in lower than the sale price.
                                    </p>
                                    <p>
                                        <strong>XI. NOTICE TO HOMEOWNER:</strong> It will be homeowner’s responsibility to request details from builder on unfinished basement level layout and builder requirements of positioning of all mechanicals.
                                    </p>


                                </v-col>
                       </v-row>

                        <v-row>
                                <v-col cols="12">
                                    <p>*This is the Master signature page for all the pages of your Selections for your home.*</p>
                                    <h3>THERE COULD BE A (MINIMUM) $75 CHARGE FOR EVERY CHANGE AFTER THE SELECTION MEETING, THIS INCLUDES CHANGES REQUESTED OR NEEDED TO HOUSE DRAWING. THEREFORE, IT IS VERY IMPORTANT TO MAKE FIRM DECISIONS IN A TIMELY MANNER.</h3><br><h3>Thank You</h3>

                                </v-col>
                            <v-col col="12" md="12">
                                <v-header><strong>Notes</strong><v-text-field v-model="section.column_60"/></v-header>
                            </v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">
                        <v-col  cols="12" class="text-right">
                        <div v-if="permissions.scope === 'create:users,fullaccess:staff'" class="text-right">
                        <v-btn
                          color="primary"
                          dark
                          class="text-right"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Submit
                        </v-btn>
                        </div>
                        </v-col>
                      </template>
                      <v-card>
                        <v-card-title>Add Vendor Email</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model ="add_vendor_email"
                                     label="Add Vendor Email"
                                     :items="['Yes', 'No']"
                                      @change="add_vendor_email"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                             <v-expand-transition>
                          <v-card
                          v-if="add_vendor_email === 'Yes'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                            <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Vendor Email
                               </v-col>
                                <v-autocomplete
                                ref="user"
                                :items="vendor_email"
                                v-model="selected_vendor.Email"
                                item-text="Email"
                                label="Vendor Email"
                                prepend-icon="mdi-account-search"
                                outlined
                                solo
                                menu-props="auto"
                                class="px-3"
                                required
                                return-object
                                ></v-autocomplete>
                              </v-row>
                              </v-form>
                            </v-container>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="permissions.scope === 'read:agent,write:agent'"
                              @click="sectionComplete"
                            >
                              Submit
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                               <v-card
                          v-if="add_vendor_email === 'No'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                                 <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sectionComplete"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                          </v-card>
                             </v-expand-transition>
                           </v-dialog>

                        </v-row>


                     </v-container>
                </v-form>
                </base-material-card>

              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                    v-model="section.column_61"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_62"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                    v-model="section.column_63"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                    v-model="section.column_64"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Save</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name:'Section1',
    data(){
	return {
		home_id: localStorage.getItem("homeid") || 0,
		alert:false,
		dialog: '',
		valid: null,
		section_id: "1",
		staff_email: 'steve@skelleymarketing.com',
		add_vendor_email:'',
		vendor_email: [],
		selected_vendor: {
				ItemId:0,
				Vendor: '',
				Name:'',
				Email: '',
				ModifiedDate:''
			},
		default_vendor: {
				ItemId:0,
				Vendor: '',
				Name:'',
				Email: '',
				ModifiedDate:''
			},
		price_section_one: {
			column_1: '',
			column_2: '',
			column_3: '',
			column_4: '',
			column_5: '',
			column_6: '',
			column_7: ''
		},
		section: {
			column_1:'',
			column_2:'',
			column_3:'',
			column_4:'',
			column_5:'',
			column_6:'',
			column_7:'',
			column_8:'',
			column_9:'',
			column_10:'',
			column_11:'',
			column_12:'',
			column_13:'',
			column_14:'',
			column_15:'',
			column_16:'',
			column_17:'',
			column_18:'',
			column_19:'',
			column_20:'',
			column_21:'',
			column_22:'',
			column_23:'',
			column_24:'',
			column_25:'',
			column_26:'',
			column_27:'',
			column_28:'',
			column_29:'',
			column_30:'',
			column_31:'',
			column_32:'',
			column_33:'',
			column_34:'',
			column_35:'',
			column_36:'',
			column_37:'',
			column_38:'',
			column_39:'',
			column_40:'',
			column_41:'',
			column_42:'',
			column_43:'',
			column_44:'',
			column_45:'',
			column_46:'',
			column_47:'',
			column_48:'',
			column_49:'',
			column_50:'',
			column_51:'',
			column_52:'',
			column_53:'',
			column_54:'',
			column_55:'',
			column_56:'',
			column_57:'',
			column_58:'',
			column_59:'',
			column_60:'',
			column_61:'',
			column_62:'',
			column_63:'',
			column_64:''
		},
		selected_user: {
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            CellNumber:'',
            HomeNumber: '',
            WorkPhone:'',
            Role:'',
            Agent:''
		},
	}
	},
	created () {
		this.getSection()
		this.getHomePrice()
		this.getVendorEmail()
		this.home_id ? this.getUserInfo() : null
	},
	methods:{
	getSection(){
	axios
	.get(process.env.VUE_APP_GET_SECTION_TWO_URL,
	{
	params: {
		home_id: this.home_id,
		section_id: this.section_id
	}
	})
	.then(response => {
        const { data } = response
        if ( data ) {
            this.section = JSON.parse(data['JsonDetails'])
        }
	})
	.catch(error => {
	console.log(error)
	})
	},
	getUserInfo(){
		axios
		.get(process.env.VUE_APP_GET_USER_URL_INFO,
		{
            params: {
                home_id: this.home_id
            }
		})
		.then(response => {
            const { data } = response
                if ( data ) {
                    return this.selected_user = data[0]
                }
		})
		.catch(error => {
		console.log(error)
		})
	},
	getHomePrice(){
		axios
		.get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
		{
				params: {
				home_id: this.home_id
		}
		})
		.then(response => {
            const { data } = response
            console.log("🚀 ~ file: Section1.vue ~ line 990 ~ getHomePrice ~ data", data)
				if ( data ) {
                    this.price_section_one = JSON.parse(data['SectionOne']) || {}
				}
		})
		.catch(error => {
		console.log(error)
		})
	},
	getVendorEmail(){
        axios
        .get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL, {})
        .then(response => {
            const { data } = response
            this.vendor_email = data
        })
        .catch(error => {
            console.log(error)
        })
	},
	updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(() => {
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
    sectionComplete(){
        var body ={
            'home_id':this.home_id,
            'section_id': this.section_id,
            "section":JSON.stringify(this.section),
        }
        const theNewContent = {
            homeOwner: {
                ...this.selected_user
            },
            sectionData: [
                {
                    Title:"Date",
                    Value: this.section.column_1
                },
                {
                    Title:"Revision #1 Date",
                    Value: this.section.column_2
                },
                {
                    Title:"Revision #2 Date",
                    Value: this.section.column_3
                },
                { 
                    Title:"Revision #3 Date",
                    Value: this.section.column_4
                },
                { 
                    Title:"Homeowner received Homeowner's Manual",
                    Value: this.section.column_5
                },
                { 
                    Title:"Additional Information Notes",
                    Value: this.section.column_6
                },
                { 
                    Title:"SHINGLES",
                    Value: this.section.column_7
                },
                { 
                    Title:"SIDING(TIME FRAME—3 WEEKS) OVATION (Double 4)",
                    Value: this.section.column_8
                },
                { 
                    Title:"CARVEDWOOD Wood Grain (Double 4)",
                    Value: this.section.column_9
                },
                { 
                    Title:"DUTCHLAP (Double 4 1/2)",
                    Value: this.section.column_10
                },
                { 
                    Title:"VINYL",
                    Value: this.section.column_11
                },
                { 
                    Title:"L.P. SMART MATERIALS FRONT ONLY",
                    Value: this.section.column_12
                },
                { 
                    Title:" Siding Color",
                    Value: this.section.column_13
                },
                { 
                    Title:"AREAS OF HOUSE FOR ACCENT",
                    Value: this.section.column_14
                },
                { 
                    Title:"CORNERS OF HOUSE (COLOR IF DIFFERENT FROM SIDING)",
                    Value: this.section.column_15
                },
                { 
                    Title:"SOFFIT & FASCIA",
                    Value: this.section.column_16
                },
                { 
                    Title:"SHAKES (If applicable & color)",
                    Value: this.section.column_17
                },
                { 
                    Title:"BOARD & BATTEN (If applicable & color)",
                    Value: this.section.column_18
                },
                { 
                    Title:"RAISED GABLE MATERIAL COLOR",
                    Value: this.section.column_19
                },
                { 
                    Title:"GORBELS IN GABLES COLOR",
                    Value: this.section.column_20
                },
                { 
                    Title:"WINDOW WRAPS (If applicable & color)",
                    Value: this.section.column_21
                },
                { 
                    Title:"COLOR",
                    Value: this.section.column_22
                },
                { 
                    Title:"WINDOWS SLIDERS",
                    Value: this.section.column_23
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_24
                },
                { 
                    Title:"CASEMENTS (OPT)",
                    Value: this.section.column_25
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_26
                },
                { 
                    Title:"SINGLE HUNGS (OPT): (LOWERS OPEN ONLY)",
                    Value: this.section.column_27
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_28
                },
                { 
                    Title:"DOUBLE HUNGS:(BOTH OPEN)",
                    Value: this.section.column_29
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_30
                },
                { 
                    Title:"TRANSOM",
                    Value: this.section.column_31
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_32
                },
                { 
                    Title:"VECTOR",
                    Value: this.section.column_33
                },
                { 
                    Title:"GRIDS ALL WINDOWS (OPTIONAL)",
                    Value: this.section.column_34
                },
                { 
                    Title:"GRID STYLE",
                    Value: this.section.column_35
                },
                { 
                    Title:"VECTOR PATIO DOOR VINYL STANDARD",
                    Value: this.section.column_36
                },
                { 
                    Title:"FRONT DOOR PAINT COLOR IF DIFFERENT THAN SIDING",
                    Value: this.section.column_37
                },
                { 
                    Title:"OPTIONAL WRAPS AROUND FRONT DOOR",
                    Value: this.section.column_38
                },
                { 
                    Title:"Color STD. White",
                    Value: this.section.column_39
                },
                { 
                    Title:'SIZE OF DOOR WRAPS:4"',
                    Value: this.section.column_40
                },
                { 
                    Title:"SIDES/BOTTOMS, 6” TOPS",
                    Value: this.section.column_41
                },
                { 
                    Title:"Notes",
                    Value: this.section.column_42
                },
                { 
                    Title:"GARAGE DOOR PAINT DIFFERENT FROM STD. WHITE",
                    Value: this.section.column_43
                },
                { 
                    Title:"OPTIONAL WRAPS AROUND GARAGE DOOR",
                    Value: this.section.column_44
                },
                { 
                    Title:"COLOR",
                    Value: this.section.column_45
                },
                { 
                    Title:'SIZE OF GARAGE DOOR WRAPS: 4”',
                    Value: this.section.column_46
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_47
                },
                { 
                    Title:'6” TOPS OF GARAGE DOOR',
                    Value: this.section.column_48
                },
                { 
                    Title:"LOCATIONS",
                    Value: this.section.column_49
                },
                { 
                    Title:"Notes",
                    Value: this.section.column_50
                },
                { 
                    Title:"EXTERIOR FRONT DOOR: STANDARD STEEL PANEL",
                    Value: this.section.column_51
                },
                { 
                    Title:"OPTIONAL",
                    Value: this.section.column_52
                },
                { 
                    Title:"DEAD BOLTS FRONT DOOR",
                    Value: this.section.column_53
                },
                { 
                    Title:"DEAD BOLTS HOUSE TO GARAGE DOOR",
                    Value: this.section.column_54
                },
                { 
                    Title:"DEAD BOLTS GARAGE SERVICE DOOR",
                    Value: this.section.column_55
                },
                { 
                    Title:"OTHER",
                    Value: this.section.column_56
                },
                { 
                    Title:"DEAD LOCK NOTES",
                    Value: this.section.column_57
                },
                { 
                    Title:"ADDITIONAL EARNEST MONEY $",
                    Value: this.section.column_58
                },
                { 
                    Title:"TERMS",
                    Value: this.section.column_59
                },
                { 
                    Title:"Notes Final",
                    Value: this.section.column_60
                },
                { 
                    Title:"Homeowner #1",
                    Value: this.section.column_61
                },
                { 
                    Title:"Date",
                    Value: this.section.column_62
                },
                { 
                    Title:"Homeowner #2",
                    Value: this.section.column_63
                },
                { 
                    Title:"Date",
                    Value: this.section.column_64
                },
            ],
            vendorData: {
                ...this.selected_vendor
            }
        }
        axios
        .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
        .then(() => {
            this.alert = true
            
            if (this.add_vendor_email[0] === 'Y')
            this.sendEmail([this.selected_vendor.Email.Email,this.staff_email],this.staff_email,"vendor_section_submit_notification",theNewContent, this.section_id)
            })
            
        .catch(error => {
            console.log(error)
        })
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
        this.close()
    },
    close() {
        this.dialog = false
        this.$nextTick(() => {
        this.add_vendor_email = Object.assign({}, this.add_vendor_email)
        this.editedIndex = -1
    })
    },
	sendEmail(to,from,template,content,section) {
		let body = {
            "to": to,
            "from": from,
            "template_name": template,
            "template_data": {
                "content": content,
                "section": section
            }
		}

		axios
		.post(process.env.VUE_APP_SEND_EMAIL_URL,body)
		.then(response => {
            const { data } = response
            console.log("🚀 ~ file: Section1.vue ~ line 1332 ~ sendEmail ~ data", data)
		})
		.catch(error => {
            console.log(error)
		})

	}
	},
	computed: {
        homeowner_emailid() {
            return this.selected_user.PrimaryEmailAddress
        },
        permissions(){
        return {scope: localStorage.getItem("permissions")}
        }
	},
}
</script>